.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  text-align: center;
  font-size: 10px;
}
.loader >div {
  background-color: #333;
  display: inline-block;
  height: 2em;
  width: 2em;
  margin: 0 1px;
  border-radius: 50%;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}
.loader .two {
  animation-delay: -1s;
}
.loader .three {
  animation-delay: -0.8s;
}
.loader .four {
  animation-delay: -0.6s;
}
.loader .five {
  animation-delay: -0.4s;
}
@-moz-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scale(0.4);
  }
  20% {
    transform: scale(1);
  }
}
@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scale(0.4);
  }
  20% {
    transform: scale(1);
  }
}
@-o-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scale(0.4);
  }
  20% {
    transform: scale(1);
  }
}
@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scale(0.4);
  }
  20% {
    transform: scale(1);
  }
}
